import { useEffect, useState } from "react";

export function ClientOnly({
  children,
  fallback,
}: {
  readonly children: React.ReactNode;
  readonly fallback?: React.ReactNode;
}): JSX.Element {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return mounted ? <>{children}</> : <>{fallback}</>;
}
